import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { CommonService } from './../shared/common.service';
import { AuthService } from '../auth/auth.service';
import { Constant } from '../constant';
import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from './../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../dialog/message-dialog/message-dialog.component';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { Overlay, CloseScrollStrategy} from '@angular/cdk/overlay';
import { CompanyService } from '../shared/company.service';

// メニュー表示中のスクロールを禁止する
export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [
    {provide: MAT_MENU_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay]}
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public readonly Constant = Constant;
  public showMenu = true;
  public backList = false;
  public spqFlg = false;
  public title = '';
  public voidFlg = false;
  public menuHideFlg = false;
  public lengthCount = 14;
  public unreadMsg = false;
  public noTitle = false;
  private subscriptionTitle: Subscription;
  private subscriptionMessage: Subscription;
  public currentPage;

  constructor(
    public auth: AuthService,
    private router: Router,
    private common: CommonService,
    private companyService: CompanyService,
    private titleService: Title,
    private meta: Meta,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // メニューの表示非表示
    this.router.events.forEach((event: NavigationEvent) => {
      // NavigationEnd
      if (event instanceof NavigationEnd) {
        // 画面上部表示（iOS対応で遅延も入れる）
        window.scrollTo(0, 0);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 200);

        if (event.urlAfterRedirects.indexOf(Constant.rpJobOfferDetail) >= 0
         || event.urlAfterRedirects.indexOf(Constant.rpLogin + '/') >= 0) {
          // 求人詳細画面、誘導ページではAuthGuardがないので再チェックする
          this.auth.isAuthenticated().subscribe(result => {
            if (result) {
              this.showMenu = true;
            } else {
              this.showMenu = false;
            }
          });
        } else if (!this.auth.loggedIn.value) {
          this.showMenu = false;
        } else if (
          event.urlAfterRedirects.indexOf(Constant.rpProfilenew) >= 0 ||
          event.urlAfterRedirects === '/' + Constant.rpError ||
          event.urlAfterRedirects === '/' + Constant.rpMaintenance ||
          event.urlAfterRedirects.indexOf(Constant.rpMailUnsubscribe) >= 0 ||
          event.urlAfterRedirects === '/'
        ) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }

        if (event.urlAfterRedirects.indexOf(Constant.rpMessagedetail) >= 0
         || event.urlAfterRedirects.indexOf(Constant.rpSpq) >= 0) {
          this.backList = true;
          if (event.urlAfterRedirects.indexOf(Constant.rpSpq) >= 0) {
            this.spqFlg = true;
          } else {
            this.spqFlg = false;
          }

        } else {
          this.backList = false;
        }

        // profilenewページ
        // メンテナンスページの場合はロゴ押下無効化
        if (
          event.urlAfterRedirects.includes(Constant.rpProfilenew) ||
          event.urlAfterRedirects.includes(Constant.rpMaintenance)
        ) {
          this.voidFlg = true;
        } else {
          this.voidFlg = false;
        }

        // profilenewページ、
        // 求人詳細ページではSPメニューを非表示
        if (
          event.urlAfterRedirects.includes(Constant.rpProfilenew) ||
          event.urlAfterRedirects.includes(Constant.rpJobOfferDetail)
        ) {
          this.menuHideFlg = true;
        } else {
          this.menuHideFlg = false;
        }

        // 就活準備コラム詳細、メンテナンスはタイトルなし
        if (event.urlAfterRedirects.includes(Constant.rpMyColumn + '/') ||
            event.urlAfterRedirects.includes(Constant.rpMaintenance)) {
          this.noTitle = true;
        } else {
          this.noTitle = false;
        }

        // noindex
        // 本番で、LP、素のログインページ(login/)、企業のデフォルト誘導ページ(login/companyname/)、
        // 求人情報詳細のみインデックスさせる
        // [/login/企業コード]がデフォルト
        // [/login/企業コード/接点タグ]はインデックさせない)
        if (
          environment.production &&
          (event.urlAfterRedirects === Constant.slash + Constant.rpLogin ||
           event.urlAfterRedirects === Constant.rpLp ||
           (event.urlAfterRedirects.indexOf(Constant.rpLogin) >= 0 &&
            event.urlAfterRedirects.split('/').length === 3) ||
           event.urlAfterRedirects.indexOf(Constant.rpJobOfferDetail) >= 0 ||
           event.urlAfterRedirects.indexOf(Constant.rpJobOfferList) >= 0 ||
           event.urlAfterRedirects.indexOf(Constant.rpContents) >= 0 ||
           event.urlAfterRedirects.indexOf(Constant.rpLpColumn) >= 0 ||
           event.urlAfterRedirects.indexOf(Constant.rpLpStartguide) >= 0 ||
           event.urlAfterRedirects.indexOf(Constant.slash + Constant.rpLpPage) >= 0 ||
           event.urlAfterRedirects.indexOf(Constant.rpCompanyAbout) >= 0 ||
           event.urlAfterRedirects.indexOf(Constant.rpContentsTag) >= 0 ||
           event.urlAfterRedirects.indexOf(Constant.rpMaterial) >= 0 )
        ) {
          this.meta.updateTag({ name: 'robots', content: 'index,follow' });
        } else {
          // それ以外のページはインデックスさせない
          this.meta.updateTag({ name: 'robots', content: 'noindex,nofollow' });
        }

        // title, description
        if (event.urlAfterRedirects.indexOf(Constant.rpLogin) === -1 &&
            event.urlAfterRedirects.indexOf(Constant.rpJobOfferDetail) === -1) {
          // ログインページ、求人情報詳細ページ以外は共通のdescriptionを設定
          // ログインページ、求人情報詳細ページはそれぞれで設定してる
          this.meta.updateTag({
            name: 'description',
            content: Constant.metaDesc
          });
        }

        // メッセージ未読チェック
        if (this.showMenu) {
          this.companyService.getMessageList();
        }

        // 最終アクセス日の情報を更新する
        this.lastAccessDate();

        // 現在のページを保存
        this.currentPage = event.urlAfterRedirects;
      }
    });

    // タイトル設定
    this.subscriptionTitle = this.common.sharedTitle$.subscribe(data => {
      this.title = data;
      this.titleService.setTitle(this.title + Constant.metaCalin);
    });

    // メッセージ未読チェックの購読
    this.checkUnreadMessage();
  }

  ngOnDestroy() {
    this.subscriptionTitle.unsubscribe();
    this.subscriptionMessage.unsubscribe();
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: any): void {
    if (this.currentPage.indexOf(Constant.rpMessagedetail) > -1) {
      // メッセージ詳細ではスクロール禁止（再読み込み時にレイアウトが崩れるのを防ぐため）
      event.preventDefault();
    }
  }

  /* テンプレート起動 */
  onMypage() {
    this.common.preCheck().then(res => {
      this.router.navigate([Constant.rpMypage]);
    })
    .catch(err => {});
  }

  onCompanyInfo() {
    this.common.preCheck().then(res => {
      this.router.navigate([Constant.rpCompanyInfo]);
    })
    .catch(err => {});
  }

  onMessage() {
    this.common.preCheck().then(res => {
      this.router.navigate([Constant.rpMessagelist]);
    })
    .catch(err => {});
  }

  onColumn() {
    this.common.preCheck().then(res => {
      this.router.navigate([Constant.rpMyColumn]);
    })
    .catch(err => {});
  }

  onProfile() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpDesire]);
      })
      .catch(err => {});
  }

  onAccount() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpAccount]);
      })
      .catch(err => {});
  }

  onHelp() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpWalkThrough + '1']);
      })
      .catch(err => {});
  }

  onContact() {
    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    // GAイベント
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionContact);
    window.open(Constant.footerUrlContact);
  }

  onLogout() {
    this.common
      .preCheck()
      .then(res => {
        // GAイベント
        this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionLogout);

        // 確認ダイアログ表示
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          width: Constant.dlWidth,
          autoFocus: false,
          data: {
            message: Constant.msgConfirmLogout,
            type: Constant.mdTypeYesNo,
            common: this.common,
            buttonText: { left: Constant.mdBtnTextNo, right: Constant.mdBtnTextYes }
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === Constant.mdBtnRight) {
            this.auth.signOut();
          }
        });
      })
      .catch(err => {});
  }

  onBack() {
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpMessagelist]);
      })
      .catch(err => {});
  }

  onCalinLogo() {
    this.common.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionLogoCalin);
    this.common
      .preCheck()
      .then(res => {
        this.router.navigate([Constant.rpMypage]);
      })
      .catch(err => {});
  }

  // リンククリック時のAnalyticsEvent
  onClickGAEvent(category, action) {
    this.common.sendGAEvent(category, action);
  }

  // 最終アクセス日の情報を更新
  private lastAccessDate() {
    // ログイン状態の確認
    this.auth.isAuthenticated().subscribe(result => {
      if (result) {
        // ログイン中であれば更新の処理
        this.common
        .apiPut('/access/lastdate')
        .then(res => {})
        .catch(err => {});
      } else {
        // 未ログイン時は何もしない
      }
    });
  }

  // メッセージの未読チェック
  private checkUnreadMessage() {
    this.subscriptionMessage = this.companyService.sharedMessages$.subscribe(data => {
      this.unreadMsg = this.companyService.checkUnreadMessage(data);
    });
  }
}
