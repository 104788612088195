<div class="all-container" (scroll)="onWindowScroll()">
  <div mat-icon-button class="data-update-button" (click)="onPageTop()">
    <img src="assets/common/data_update.svg" />
  </div>
  <div class="header">
    <div class="text">
      気になる企業と気軽につながりましょう！
    </div>
    <div class="header-link">
      <img
        src="assets/common/question.svg"
        class="information-icon"
      />
      <a (click)="onWhatIsTalentPool()" class="text">
        つながるとは
      </a>
    </div>
    <div class="search-btn-area">
      <button mat-button (click)="onSearch()" class="search-btn">表示条件の変更</button>
    </div>
  </div>
  <div class="bottom-separator"></div>
  <div *ngIf="allCompanyInfoList === null && isDataGetting" class="spinner">
    <mat-spinner diameter="60"></mat-spinner>
  </div>
  <div class="company-info-container">
    <div class="no-company-info" *ngIf="allCompanyInfoList && allCompanyInfoList.length == 0">
      候補となる企業がありません。<br />
      申し訳ございませんが、候補企業が増えるまでお待ちください。
    </div>
    <ng-container *ngFor="let companyInfo of allCompanyInfoList; index as idx">
      <ng-container *ngIf="companyInfo.code == null">
        <div *ngIf="!companyInfo.endFlg" class="scroll-message">
          <span>↓スクロールして企業情報を取得する↓</span>
        </div>
        <div *ngIf="isDataGetting" class="spinner scroll">
          <mat-spinner diameter="60"></mat-spinner>
        </div>
        <div *ngIf="companyInfo.endFlg" class="endRow">
          <span>候補企業は以上です。</span>
        </div>
      </ng-container>
      <div *ngIf="companyInfo.code != null" class="contents">
        <div *ngIf="companyInfo.logo_url != Constant.empty" class="name-container">
          <img class="logo" src="{{ companyInfo.logo_url }}" *ngIf="companyInfo.logo_url"
            appImageLoadingError />
          <span class="name"> {{ companyInfo.name }} </span>
        </div>
        <div *ngIf="companyInfo.pr_image_url != Constant.empty" class="image-container">
          <img class="image" src="{{ companyInfo.pr_image_url }}" *ngIf="companyInfo.pr_image_url"
            appImageLoadingError />
        </div>
        <div class="main-copy-area">
          <span class="text">{{ companyInfo.summary }}</span>
        </div>
        <mat-divider></mat-divider>
        <div class="detailinfo-area">
          <div *ngIf="companyInfo.employment_area && companyInfo.employment_area != Constant.empty" class="row">
            <div class="title" valign="top">●勤務地</div>
            <div class="value">{{ companyInfo.employment_area }}</div>
          </div>
          <div *ngIf="companyInfo.industry && companyInfo.industry != Constant.empty" class="row">
            <div class="title" valign="top">●業種</div>
            <div class="value">{{ companyInfo.industry }}</div>
          </div>
          <div *ngIf="companyInfo.establishment && companyInfo.establishment != Constant.empty" class="row">
            <div class="title">●設立</div>
            <div class="value">{{ companyInfo.establishment }}</div>
          </div>
          <div *ngIf="companyInfo.capital && companyInfo.capital != Constant.empty" class="row">
            <div class="title">●資本金</div>
            <div class="value">{{ companyInfo.capital }}円</div>
          </div>
          <div *ngIf="companyInfo.employees && companyInfo.employees != Constant.empty" class="row">
            <div class="title">●従業員</div>
            <div class="value">{{ companyInfo.employees }}人</div>
          </div>
          <div *ngIf="companyInfo.homepage && companyInfo.homepage != Constant.empty" class="row">
            <div class="title">●公式サイト</div>
            <div class="value">
              <a (click)="onHomepage(companyInfo.homepage)">{{ companyInfo.homepage }}
                <img class="resource-icon" src="assets/common/launch.svg" />
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="registering && registeringCampany === idx" class="sending-spinner-row">
          <div class="spinner-half"></div>
          <div class="spinner-half spinner">
            <mat-spinner diameter="30"></mat-spinner>
          </div>
        </div>
        <div class="button-area">
          <div class="left-button">
            <div class="readmore-btn" (click)="onMoreInfo(companyInfo.code)">
              <div class="btn-text">詳細ページへ</div>
              <img class="btn-icon" src="assets/common/arrow_right.svg" />
            </div>
          </div>
          <div class="right-button">
            <div class="tsunagaru-btn" [class.disabled]="registering" (click)="checkRegistPool(idx)">
              つながる
            </div>
          </div>
        </div>
        <div class="info-title">公開中の情報</div>
        <div class="summary-info-area">
          <div class="pr-content">
            <div class="label">コンテンツ</div>
            <div class="count">{{ companyInfo.cts_cnt + companyInfo.doc_cnt }}</div>
            <div class="label">件</div>
          </div>
          <div class="job-offer">
            <div class="label">求人情報</div>
            <div class="count">{{ companyInfo.jo_cnt }}</div>
            <div class="label">件</div>
          </div>
        </div>
        <div class="bottom-separator"></div>
      </div>
    </ng-container>
    <div class="spacer"></div>
    <div class="bottom-line" id="bottom-line"></div>
  </div>
</div>
<app-profile-message-buttons></app-profile-message-buttons>
