
import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Constant } from '../../constant';
import { CommonService } from '../../shared/common.service';
import { TsunagaruDialogComponent } from '../../dialog/tsunagaru-dialog/tsunagaru-dialog.component';
import { AuthService } from '../../auth/auth.service';
import { CommonCompanyService } from './../../shared/common-company.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.css']
})
export class CompanyHeaderComponent implements OnInit, OnDestroy {
  public companyData;
  public readonly Constant = Constant;
  public logoUrl = '';
  public registering = false;
  public entryUrl = '';
  public menuFixed = false;
  public showSpMenu = false;
  public showContentsSubmenu = false
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  private contact;
  private contactQuestion;
  private subscriptionTsunagaruInfo: Subscription;
  private subscriptionCompany: Subscription;
  private subscriptionAuth: Subscription;
  private subscriptionPage: Subscription;
  private scrollY
  private lastSubmenuElement = ''  // 最終マウスアウトの要素　サブメニューなら再度開かない
  private submenuClassName = 'contents-submenu'

  constructor(
    private router: Router,
    public auth: AuthService,
    private common: CommonService,
    private dialog: MatDialog,
    private commonCompany: CommonCompanyService,
  ) {
  }

  ngOnInit(): void {
    // 企業情報設定
    this.subscriptionCompany = this.commonCompany.sharedHeaderData$.subscribe(data => {
      this.companyData = data;
      this.setData();
    });

    // つながる処理の終了
    this.subscriptionTsunagaruInfo = this.commonCompany.sharedTsunagaruExit$.subscribe(data => {
      this.registering = false;
      this.commonCompany.progressViewDetach();
      if (!data) {
        this.common.showToastMessage(Constant.msgTsunagaruError);
      }
    });

    // activeページの設定
    this.subscriptionPage = this.commonCompany.sharedHeaderPage$.subscribe(page => {
      this.onContentsMenuOff(null)
      this.lastSubmenuElement = this.submenuClassName
      if (this.companyData) {
        this.companyData.page = page;
      }
    });
  }

  ngOnDestroy(): void {
    this.commonCompany.progressViewDetach();

    if (this.subscriptionTsunagaruInfo) {
      this.subscriptionTsunagaruInfo.unsubscribe();
    }
    if (this.subscriptionCompany) {
      this.subscriptionCompany.unsubscribe();
    }
    if (this.subscriptionAuth) {
      this.subscriptionAuth.unsubscribe();
    }
    if (this.subscriptionPage) {
      this.subscriptionPage.unsubscribe();
    }
  }

  // 画面のスクロールでメニューを固定
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.classList.contains(Constant.elementClassScrollBlock)) {
      return;
    }

    if (this.auth.loggedIn.value) {
      if (window.scrollY > 53) {
        this.menuFixed = true;
      } else {
        this.menuFixed = false;
      }
    } else {
      this.menuFixed = true;
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    // ログインチェック
    this.subscriptionAuth = this.auth.isAuthenticated().subscribe(result => {
      this.onWindowScroll();
    });
  }

  @HostListener('window:resize')
  onResize() {
    if (window.innerWidth > 1073) {
      this.menuClose()
    }
  }

  onTopPage(page?) {
    this.trigger.closeMenu();
    this.onContentsMenuOff(null)
    window.scroll(0, 0);
    this.common.preCheck().then(res => {});
    // ヘッダーのactive切り替え
    if (page) {
      this.companyData.page = page
    }
  }

  onContentsMenuOn(event) {
    // 表示中のサブメニューからのマウスエンターの場合、再表示しない（アニメーション対応）
    if (this.lastSubmenuElement.indexOf(this.submenuClassName) > -1 && event.srcElement.className.indexOf(this.submenuClassName) > -1) {
      return
    }
    this.showContentsSubmenu = true
  }

  onContentsMenuOff(event) {
    this.showContentsSubmenu = false
    if (event) {
      this.lastSubmenuElement = event.srcElement.className
    }
  }

  onMenuOpen() {
    this.scrollY = window.scrollY
    this.showSpMenu = true
    document.documentElement.classList.add(Constant.elementClassMyScrollBlock);
  }

  onMenuClose() {
    this.showSpMenu = false
    document.documentElement.classList.remove(Constant.elementClassMyScrollBlock);
    window.scrollTo(0, this.scrollY)
  }

  menuClose() {
    this.trigger.closeMenu();
  }

  onDisabledMenu(event) {
    event.stopPropagation();
  }

  // つながる
  onTsunagaru() {
    this.registering = true;
    this.menuClose();

    this.common.preCheck().then(res => {
      // 接点タグがあれば質問取得
      this.contact = localStorage.getItem(Constant.lsContactCode + this.companyData.code);
      if (this.contact) {
        this.commonCompany.getContactInfo(this.companyData.code, this.contact).then(res => {
          this.contactQuestion = res.data.question;
          this.tsunagaruExec();
        })
        .catch(err => {
          this.registering = false;
          this.common.showToastMessage(Constant.msgTsunagaruError);
        });

      } else {
        this.tsunagaruExec();
      }
    })
    .catch(err => {
      this.registering = false;
    });
  }

  // マイページ
  onMypage() {
    this.common.preCheck().then(res => {
      this.router.navigate([Constant.rpMypage]);
    })
    .catch(err => {});
  }

  // オンラインチェック
  checkOnline(): Boolean {
    return this.common.preCheckWindowOpen();
  }

  private setData() {
    // 企業情報取得
    this.getCompanyLogo();

    // コンテンツサブメニュー　記事画像取得
    if (this.companyData.cts_cnt > 0) {
      this.getContentsTopImage()
    }

    // コンテンツサブメニュー 資料の画像取得
    this.getCompanyDocument()

    // 採用トップへのリンク
    this.entryUrl = Constant.rpLogin + Constant.slash + this.companyData.code;
  }

  private tsunagaruExec() {
    // プログレスビューを表示
    this.commonCompany.progressViewAttach();
    this.commonCompany.onTsunagaru(this.companyData.code, this.companyData.name, this.auth.loggedIn.value, this.contact, this.contactQuestion);
  }

  // つながるとは　ダイアログ表示
  onWhatIsTalentPool() {
    this.menuClose();

    const data = {companyName: this.companyData.name};
    const dialogRef = this.dialog.open(TsunagaruDialogComponent, {
      width: Constant.dlWidthTsunagaru,
      autoFocus: false,
      data: data
    });
    dialogRef.afterClosed().subscribe(res => {
    });
  }

  // 企業ロゴ画像取得
  private getCompanyLogo() {
    this.common.getCompanyLogo(this.companyData.code)
    .then(url => {
      this.logoUrl = url;
    })
    .catch(err => {
      this.common.showToastMessage(Constant.msgErrorNetwork);
    });

  }

  private getContentsTopImage() {
    // コンテンツの画像を取得する
    const contentsCode = this.companyData.cts_top_code
    const apiPath = '/content/' + contentsCode + '/url';
    this.common.apiGetBeforeLogin(apiPath)
      .then(res => {
        if (res.data.url === '' || res.data.url === null) {
          this.companyData.cts_top_image = ''
        } else {
          this.companyData.cts_top_image = res.data.url;
        }
      })
      .catch(err => {
        this.common.debug().log(err);
      });
  }

  // 資料の画像を取得する
  private getCompanyDocument() {
    this.common.preCheck().then(check => {
      const materialUrlApiPath = '/company/info/' + this.companyData.code + '/documenturl';
      this.common.apiGetBeforeLogin(materialUrlApiPath).then(res => {
        if (res.data.code) {
          this.companyData.material_code = res.data.code
        } else {
          this.companyData.material_code = ''
        }
        if (res.data.image_url) {
          this.companyData.material_image_url = res.data.image_url;
        } else {
          this.companyData.material_image_url = ''
        }
      })
      .catch(err => {
        if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
          this.common.debug().log(err);
        }
      });
    })
    .catch(err => {});
  }

}
