import { CommonModule, DOCUMENT } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CommonCompanyService } from './../shared/common-company.service';
import { CommonLpService } from './../shared/common-lp.service';
import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../shared/common.service';
import { AuthService } from '../auth/auth.service';
import { Constant } from './../constant';
import { MatDialog } from '@angular/material/dialog';
import { ContactService } from '../shared/contact.service';
import { Subscription } from 'rxjs';
import { MessageDialogComponent } from './../dialog/message-dialog/message-dialog.component';
import { sprintf } from 'sprintf-js';
import { PdfviewerDialogComponent } from '../dialog/pdfviewer-dialog/pdfviewer-dialog.component';
import { PdfViewerComponent } from './../pdf-viewer/pdf-viewer.component';
import { PdfOverlayService } from '../overlay/pdf-overlay.service';
import { SliderComponent } from './../common-component/slider/slider.component';
import { TsunagaruComponent } from './../common-component/tsunagaru/tsunagaru.component';

@Component({
  selector: 'app-company-top',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    SliderComponent,
    TsunagaruComponent,
  ],
  templateUrl: './company-top.component.html',
  styleUrl: './company-top.component.css'
})
export class CompanyTopComponent {
  public readonly Constant = Constant;
  public showSpinner: boolean;
  public companyCode: string;
  public contact: string;
  public companyInfo;
  public prImageUrl;
  public document;  // pickup資料
  public documentShowSpinner = false;
  public registering = false;  // プール登録中フラグ
  public pdfPage = 1;  // PDF表示ページ
  public pdfTotalPage = 0;  // PDF全ページ数
  public headerData;
  public contentsListData
  public jobofferListData
  public tsunagaruData
  public fixSideMenu = false
  public menuTop = 'top'
  public menuMessage = 'message'
  public menuContents = 'contents'
  public menuJoboffer = 'joboffer'
  public menuProfile = 'profile'
  public activeSideMenu = this.menuTop
  private loginCompFlg = false;
  private isLogin = false;
  private contactQuestion;  // 接点の質問
  private subscriptionContactInfo: Subscription;
  private subscriptionContactHistory: Subscription;
  private subscriptionTsunagaruInfo: Subscription;
  private safariFlg = false;
  private pdfViewerWidth = 0
  private pdfViewerMaxHeight = 495

  @ViewChild('sidemenu') sidemenu;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private common: CommonService,
    private commonCompany: CommonCompanyService,
    private auth: AuthService,
    private dialog: MatDialog,
    private contactService: ContactService,
    private pdfOverlay: PdfOverlayService,
    private commonLp: CommonLpService,
    @Inject(DOCUMENT) private dom
  ) {
    // companycode,接点
    this.companyCode = this.activatedRoute.snapshot.params['code'];
    this.contact = this.activatedRoute.snapshot.params['contact'];

    this.common.setTitle(Constant.empty);

    // canonicalタグ設定
    const url = this.dom.location.origin + '/' + Constant.rpLogin + '/' + this.companyCode;
    this.commonLp.createCanonicalURL(this.dom, url);
  }

  ngOnInit(): void {
    this.commonCompany.setCompanyHeaderPage(Constant.rpCompanyTop);

    if (!this.common.preCheckWindowOpen()) {
      return;
    }
    this.showSpinner = true;

    if (this.contact) {
      // 接点タグがあれば、ローカルストレージに保存
      localStorage.setItem(Constant.lsContactCode  + this.companyCode, this.contact);
    } else {
      // 接点タグなし＆ローカルストレージにあれば、リダイレクトする
      this.contact = localStorage.getItem(Constant.lsContactCode  + this.companyCode);
      if (this.contact) {
        this.router.navigate([Constant.rpLogin, this.companyCode, this.contact]);
        return;
      }
    }

    // 企業情報取得
    this.getCompanyInfo();

    if (this.contact) {
      // 接点のイベント登録
      this.setContactEvent();
      // 接点の有効性確認、質問取得
      this.contactService.getInfo(this.companyCode, this.contact);
    }

    // ログインチェック
    this.auth.isAuthenticated().subscribe(result => {
      if (result) {
        this.isLogin = true;
        // 接点履歴確認
        if (this.contact) {
          this.contactService.getHistory(this.companyCode, this.contact);
        }
      } else {
        // PV記録のため、仮のID設定
        this.commonCompany.createTempTalentId()
      }

      // 接点なしならPV登録
      if (!this.contact) {
        this.postContactPageview(Constant.empty);
      }
    });

    // ログイン完了ダイアログ表示用
    if (localStorage.getItem(Constant.lsLoginCompFlg)) {
      this.loginCompFlg = true;
    }
  }

  ngOnDestroy(): void {
    this.commonCompany.progressViewDetach();

    if (this.subscriptionContactInfo) {
      this.subscriptionContactInfo.unsubscribe();
    }
    if (this.subscriptionContactHistory) {
      this.subscriptionContactHistory.unsubscribe();
    }
    if (this.subscriptionTsunagaruInfo) {
      this.subscriptionTsunagaruInfo.unsubscribe();
    }

    // canonicalタグ削除
    this.commonLp.deleateCanonicalURL(this.dom);
  }

  ngAfterViewInit() {
    // つながる処理の終了
    this.subscriptionTsunagaruInfo = this.commonCompany.sharedTsunagaruExit$.subscribe(data => {
      this.registering = false;
      this.commonCompany.progressViewDetach();
      if (!data) {
        this.common.showToastMessage(Constant.msgTsunagaruError);
      }
    });

    // safari判別
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1) {
      this.safariFlg = true;
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if (this.registering) {
      // つながる実行中は何もしない
      return;
    }

    // 接点タグチェック
    this.contact = localStorage.getItem(Constant.lsContactCode  + this.companyCode);

    // ログインチェック
    this.commonCompany.onFocusCheckLogin(this.isLogin).subscribe(res => {
      this.isLogin = res;
      if (this.contentsListData) {
        this.contentsListData.isLogin = this.isLogin
      }
      if (this.jobofferListData) {
        this.jobofferListData.isLogin = this.isLogin
      }
      if (this.tsunagaruData) {
        this.tsunagaruData.isLogin = this.isLogin
      }

      if (res) {
        // 接点履歴確認
        if (this.contact) {
          this.contactService.getHistory(this.companyCode, this.contact);
        }
      }
    });
  }

  // 画面のスクロールでメニューを固定
  @HostListener('window:scroll', [])
  onWindowScroll() {
    // サイドメニュー固定
    const messageDiv = document.getElementById(this.menuMessage)
    if (!messageDiv) {
      return
    }
    const scroll = window.scrollY;        //スクロール量
    const winHeight = window.innerHeight; //画面の高さ
    const sideHeight = this.sidemenu.nativeElement.clientHeight - 40

    let sidemenuOffset = 56
    if (this.isLogin) {
      sidemenuOffset = 109
    }

    if (scroll >= messageDiv.offsetTop + sideHeight/2 - winHeight/2 + sidemenuOffset){
      // スクロール中、画面中央で固定
      this.fixSideMenu = true
      this.sidemenu.nativeElement.style.top = 'calc(50% - ' + sideHeight/2 + 'px)'

    } else {
      // 固定なし
      this.fixSideMenu = false
      this.sidemenu.nativeElement.style.top = '-20px'
    }

    // サイドメニューactive
    let activeMenu = this.menuTop
    const menuAry = [this.menuProfile, this.menuJoboffer, this.menuContents, this.menuMessage]
    const scrollOffset = 250
    if (scroll > 10) {
      for (let i=0; i<menuAry.length; i++) {
        const div = document.getElementById(menuAry[i])
        if (!div) {
          continue
        }
        const pos = div.getBoundingClientRect().top + scroll
        if (scroll > pos - winHeight + scrollOffset) {
          activeMenu = menuAry[i]
          break
        }
      }
    }
    this.activeSideMenu = activeMenu
  }

  @HostListener('window:resize')
  onResize() {
    // PDFリサイズ　横幅拡大時に縦を伸ばす
    if (this.pdfTotalPage > 0 && this.pdfViewerWidth > 0) {
      const viewer = document.getElementsByClassName('pdf-viewer')  as HTMLCollectionOf<HTMLElement>;
      const canvas = document.querySelector('app-company-top .canvasWrapper canvas');
      if (viewer && viewer[0].clientHeight < this.pdfViewerMaxHeight && viewer[0].clientWidth>this.pdfViewerWidth) {
        let h = Math.ceil(viewer[0].clientHeight * (viewer[0].clientWidth / this.pdfViewerWidth))
        if (h >= this.pdfViewerMaxHeight) {
          h = this.pdfViewerMaxHeight
        }
        viewer[0].style.height = h + 'px'
      }

      setTimeout(() => {
        if (canvas && viewer && canvas.clientHeight>0 && canvas.clientWidth != this.pdfViewerWidth) {
          viewer[0].style.height = Math.ceil(canvas.clientHeight) + 'px';
          this.pdfViewerWidth = canvas.clientWidth
        }
      }, 200)
    }
  }

  /* テンプレート起動 */
  // つながる
  onTsunagaru() {
    this.registering = true;

    this.common.preCheck().then(res => {
      this.contact = localStorage.getItem(Constant.lsContactCode  + this.companyCode);
      if (this.contact) {
        // 質問取得
        this.commonCompany.getContactInfo(this.companyCode, this.contact).then(res => {
          this.contactQuestion = res.data.question;
          this.commonCompany.onTsunagaru(
            this.companyCode, this.companyInfo.name, this.isLogin, this.contact, this.contactQuestion
          );
        })
        .catch(err => {
          this.registering = false;
          this.common.showToastMessage(Constant.msgTsunagaruError);
          this.commonCompany.progressViewDetach();
        });

      } else {
        this.commonCompany.onTsunagaru(this.companyCode, this.companyInfo.name, this.isLogin, null, null);
      }
    })
    .catch(err => {
      this.registering = false;
      this.commonCompany.progressViewDetach();
    });
  }

  onMenuClick(id) {
    if (id === this.menuTop) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    } else {
      const header = document.getElementById(id)
      let offset = 80
      if (id === this.menuMessage || id === this.menuProfile) {
        offset = -10
      }
      window.scroll({
        top: header.offsetTop + offset,
        behavior: 'smooth'
      })
    }

  }

  onLink(url) {
    this.router.navigate([url, this.companyCode]);
  }

  // 資料を見る
  onMaterial() {
    this.router.navigate([Constant.rpMaterial, this.companyCode, this.document.code]);
  }

  // PDFダイアログ表示
  public onPdfDialog() {
    this.common.preCheck().then(res => {
      this.dialog.open(PdfviewerDialogComponent, {
        width: Constant.dlWidthPdf,
        maxWidth: '99vw',
        autoFocus: false,
        panelClass: 'pdf-viewer-dialog',
        data: {
          url: this.document.url,
          blueFlg: true
        }
      });
    })
    .catch(err => {});
  }

  /* API */
  // 企業情報取得
  private getCompanyInfo() {
    this.commonCompany.getCompanyInfo(this.companyCode).then(data => {
      if (!data) {
        this.showSpinner = false;
        this.common.showToastMessage(Constant.msgErrorNetwork);
        return;
      }
      this.companyInfo = data;

      // メインカラー
      this.commonCompany.setCompanyMainColor(this.companyInfo.main_color)

      // PDF（会社説明資料）を取得する
      if (this.companyInfo.document_path) {
        this.getCompanyDocument();
      }

      // コンテンツ、求人取得
      this.getConentsData()
      this.getJobOfferData()

      // ヘッダ用データ
      this.commonCompany.setCompanyHeaderData(this.companyCode, data, Constant.rpCompanyTop);

      // つながる用データ
      this.tsunagaruData = {
        companyCode: this.companyCode,
        name: this.companyInfo.name,
        display_name: this.companyInfo.display_name,
        isLogin: this.isLogin,
      }

      // SEOメタタグセット
      this.setSeoMeta(data.name, data.summary);

      this.showSpinner = false;

      // ログイン済みダイアログ表示
      if (!this.contact) {
        setTimeout(() => {
          this.showLoginCompDialog();
        }, 500);
      }
    });

    // 企業のPR画像を取得する
    this.common.getCompanyPrImage(this.companyCode)
    .then(url => {
      this.prImageUrl = url;
    })
    .catch(err => {
      this.common.showToastMessage(Constant.msgErrorNetwork);
    });
  }

  // 誘導ページのページビューを登録する
  private postContactPageview(tag) {
    const apiPath = '/company/contactpageview';
    const body = {
      companycode: this.companyCode,
      contacttag: tag
    };
    if (this.isLogin) {
      this.common.apiPost(apiPath, body);
    } else {
      this.common.apiPostBeforeLogin(apiPath, body);
    }
  }

  // 会社説明PDFのURL取得
  private getCompanyDocument() {
    this.common.preCheck().then(check => {
      const documentUrlApiPath = '/company/info/' + this.companyCode + '/documenturl';
      this.common.apiGetBeforeLogin(documentUrlApiPath).then(res => {
        if (res.data.url) {
          this.document = res.data;
          this.documentShowSpinner = true;

          // 30秒読み込みできなければエラートースト表示
          setTimeout(() => {
            this.pdfError();
          }, Constant.apiTimeoutSecond);
        }
      })
      .catch(err => {
        if (err.status !== Constant.MaintenanceNG && err.status !== Constant.VersionNG) {
          this.common.debug().log(err);
        }
      });
    })
    .catch(err => {});
  }

  // コンテンツ情報を取得する
  private getConentsData() {
    let apiPath = '/contents/' + this.companyCode;
    const body = {
      release_date: '',
      is_detail: false,
      except_id: 0,
      cnt: 9,
      offset: 0
    };

    if (this.isLogin) {
      // コンテンツを取得
      this.common.apiPost(apiPath, body)
        .then(res => {
          this.afterGetContentData(res);
        })
        .catch(err => {
          this.common.debug().log(err);
          this.common.showToastMessage(Constant.msgErrorNetwork);
        });
    } else {
      apiPath += '/noauth';
      const tempId = localStorage.getItem(Constant.lsTempTalentId)
      if (tempId) {
        body[Constant.apTempTalentId] = tempId
      }
      this.common.apiPostBeforeLogin(apiPath, body)
        .then(res => {
          this.afterGetContentData(res);
        })
        .catch(err => {
          this.common.debug().log(err);
          this.common.showToastMessage(Constant.msgErrorNetwork);
        });
    }
  }
  // コンテンツ取得後の処理
  private afterGetContentData(res) {
    if (res.data.contents.length>0) {
      this.contentsListData = {
        list: res.data.contents,
        companyCode: this.companyCode,
        companyName: this.companyInfo.name,
        isLogin: this.isLogin,
        type: Constant.tlContents,
      }
      // 企業のロゴとコンテンツの画像を取得する
      this.getContentsImage(res.data.contents);
    }
  }

  // コンテンツ画像取得
  private getContentsImage(currentData) {
    for (let i = 0; i < currentData.length; i++) {
      if (currentData[i].image_path === Constant.empty) {
        currentData[i].image_url = ''
        continue;
      }
      // コンテンツの画像を取得する
      const contentsCode = currentData[i].code;
      const apiPath = '/content/' + contentsCode + '/url';
      this.common.apiGetBeforeLogin(apiPath)
        .then(res => {
          if (res.data.url === '' || res.data.url === null) {
            currentData[i].image_url = '';
          } else {
            currentData[i].image_url = res.data.url;
          }
        })
        .catch(err => {
          this.common.debug().log(err);
        });
    }
  }

  // 求人情報取得
  private getJobOfferData() {
    const body = {
      release_date: '',
      data_filter: false,
      is_jobdetail: false,
      except_job_id: 0,
      cnt: 9,
      offset: 0
    };

    this.commonCompany.getJobOfferData(body, this.companyCode, this.companyInfo.name, this.isLogin)
    .then(data => {
      this.jobofferListData = data
    })
    .catch(err => { });
  }

  /* その他プライベート関数 */
  // ログイン完了ダイアログ表示
  private showLoginCompDialog() {
    localStorage.removeItem(Constant.lsLoginCompFlg);
    if (!this.loginCompFlg || !this.companyInfo.name) {
      return;
    }

    const message = sprintf(Constant.msgLoginComplete, this.companyInfo.name);

    if (this.safariFlg) {
      document.body.classList.add(Constant.elementClassScrollBlock);
    }
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: Constant.dlWidth,
      autoFocus: false,
      disableClose: true,
      data: { message: message, type: Constant.mdTypeTsunagaru, common: this.common, closeFlg: false, blueFlg: true }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (this.safariFlg) {
        document.body.classList.remove(Constant.elementClassScrollBlock);
      }
      if (result) {
        // プログレスビュー表示
        this.commonCompany.progressViewAttach();
        // つながる
        this.onTsunagaru();
      }
    });
  }

  // 接点のイベント登録
  private setContactEvent() {
    // 接点が有効かどうか
    this.subscriptionContactInfo = this.contactService.sharedInfo$.subscribe(data => {
      if (data.status === Constant.OK) {
        // 有効
        // ページビュー登録
        this.postContactPageview(this.contact)

        // ログイン済みダイアログ表示
        setTimeout(() => {
          this.showLoginCompDialog();
        }, 500);
      } else if (data.status === Constant.NG) {
        // 無効
        localStorage.removeItem(Constant.lsContactCode + this.companyCode);
        this.router.navigate([Constant.rpLogin, this.companyCode]);
      } else {
        this.common.showToastMessage(Constant.msgErrorNetwork);
      }
    });

    // 接点履歴
    this.subscriptionContactHistory = this.contactService.sharedHistory$.subscribe(data => {
      if (data) {
        // 接点ありの場合、接点情報を削除
        this.contact = null;
        localStorage.removeItem(Constant.lsContactCode + this.companyCode);
      }
    });
  }

  // SEOのメタタグ設定
  private setSeoMeta(companyName, summary) {
    const title = companyName + Constant.textTsunagaru;
    let description = companyName + Constant.metaDescCompany
    if (summary) {
      description = summary
    }
    this.commonCompany.setSeoMeta(title, description);
  }

 // PDF関連
 public pdfLoadComplete(pdfData) {
   this.pdfTotalPage = pdfData.numPages;

   // PDFの高さを合わせる
   setTimeout(() => {
    if (this.resizePdfViewerSize()) {
      this.documentShowSpinner = false;
    }
  }, 200);
 }
 public prevBtn() {
   this.pdfPage--;
 }
 public nextBtn() {
   this.pdfPage++;
 }
 public pdfError() {
   if (this.pdfTotalPage === 0) {
    this.common.showToastMessage(Constant.msgErrorNetwork);
    this.documentShowSpinner = false;
   }
 }
 // リサイズ時に高さを合わせる
  pdfRendered() {
    this.resizePdfViewerSize()
  }

  private resizePdfViewerSize() {
    const canvas = document.querySelector('app-company-top .canvasWrapper canvas');
    const viewer = document.getElementsByClassName('pdf-viewer')  as HTMLCollectionOf<HTMLElement>;
    if (canvas && canvas.clientHeight>0 && canvas.clientWidth != this.pdfViewerWidth) {
      let h = Math.ceil(canvas.clientHeight)
      if (h >= this.pdfViewerMaxHeight) {
        h = this.pdfViewerMaxHeight
      }
      viewer[0].style.height = h + 'px';
      this.pdfViewerWidth = Math.ceil(canvas.clientWidth)
      return true
    } else {
      return false
    }
  }

}
