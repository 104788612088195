<div *ngIf="showSpinner" class="spinner blue">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<div *ngIf="detailInfo">
  <div class="rect pdf" *ngIf="detailInfo.document_url">
    <div class="overlay-area">
    <div *ngIf="pdfTotalPage==0 && materialShowSpinner" class="spinner blue">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
    <pdf-viewer [src]="detailInfo.document_url" class="pdf-viewer" [show-all]="false" [page]="pdfPage" [render-text]="false"
    [fit-to-page]="true" [original-size]="false" [zoom-scale]="'page-fit'" [autoresize]="true"
    (after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()"
    (page-rendered)="pdfRendered()"></pdf-viewer>
    <div class="pdf-viewer-navi" *ngIf="pdfTotalPage>0">
      <div class="leftbtn">
        <button mat-flat-button (click)="prevBtn()" [disabled]="pdfPage==1">＜</button>
        <button mat-flat-button (click)="nextBtn()" [disabled]="pdfPage==pdfTotalPage">＞</button>
      </div>
      <span>{{ pdfPage }} / {{ pdfTotalPage }}</span>
      <div class="rightbtn">
        <button mat-icon-button (click)="onPdfDialog()" class="pc"><mat-icon>fullscreen</mat-icon></button>
      </div>
    </div>
    </div>
    <h3 class="pdf-title-area">{{ detailInfo.title }}</h3>
  </div>

</div>
<div *ngIf="detailInfo === ''" class="not-contents">
  コンテンツが存在しないか、<br>公開されていません。
</div>
<div class="unit" *ngIf="otherList?.length > 0">
  <div class="header">他の資料</div>
  <div class="other-area">
    <ng-container *ngFor="let item of otherList; index as i" #sameCateContents>
      <ng-container *ngIf="item.code == null">
        <ng-container *ngIf="item.endFlg == false">
          <div class="more-contents">
            <div *ngIf="isDataGetting" class="spinner blue">
              <mat-spinner diameter="30"></mat-spinner>
            </div>
            <div class="more-contents-btn" (click)="onNextData()">
              もっと見る
              <!-- <img src="assets/company/arrow_under.svg"> -->
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5575 0.221192L5 3.65619L8.4425 0.221191L9.5 1.27869L5 5.77869L0.5 1.27869L1.5575 0.221192Z" fill="#434343" class="s"/>
              </svg>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="box" *ngIf="item.code != null">
        <div class="rect">
          <div class="image-container">
            <a *ngIf="item.image_url" routerLink="/{{Constant.rpMaterial}}/{{companyCode}}/{{item.code}}">
              <img class="image" [src]="item.image_url" appImageLoadingError />
            </a>
            <a *ngIf="item.image_url===''" routerLink="/{{Constant.rpMaterial}}/{{companyCode}}/{{item.code}}">
              <img class="image noimage" lazyLoad="assets/common/noimage.png" appImageLoadingError />
              <div class="image-back-noimage"></div>
            </a>
          </div>
        </div>
        <div class="title-container">
          <a class="title" routerLink="/{{Constant.rpMaterial}}/{{companyCode}}/{{item.code}}">{{ item.title }}</a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="unit" *ngIf="contentsListData">
  <div class="header">新着記事</div>
    <app-slider [data]="contentsListData"></app-slider>
</div>
<div class="unit job" *ngIf="jobofferListData">
  <div class="header">新着求人</div>
    <app-slider [data]="jobofferListData"></app-slider>
</div>

<app-tsunagaru [data]="tsunagaruData" *ngIf="tsunagaruData"></app-tsunagaru>

<app-company-footer [companyInfo]="companyInfo" *ngIf="companyInfo"></app-company-footer>
